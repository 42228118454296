import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './SignUp.css';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from '../../config.json';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import ReCAPTCHA from "react-google-recaptcha";
import { T } from 'antd/lib/upload/utils';
import {Helmet} from 'react-helmet';

class SignUp extends React.Component<any, any> {
  private dateValue:Date=new Date();
  private pass2Style;
    constructor(props: any) {
        super(props);
        this.state = {
          register : {
            Email: '',
            Password: '',
            Name : '',
            Username: '',
            Token:''
          },
            notice: any
        };
        
    }
    
      changeHandler=(e: any)=>{
        let register = this.state.register;
        register[e.target.name]= e.target.value;
        this.setState({register: register});
      }
      changePass2Handler=(e: any)=>{
        let register = this.state.register;
        if(register["Password"]!== e.target.value)
        {
          this.pass2Style={ borderColor : "Red" };
          this.setState({notice:  <label style={{ marginLeft: "5px", color:"Red" }} > Nhập hai lần mật khẩu không giống nhau </label> });   
        }
        else
        {
          this.pass2Style=null;
          this.setState({notice: ""});
        }
      }
      submitHandler = (e: any) =>{
        e.preventDefault();
        // console.log(this.state.register)
        axios
        .post(configData.serviceUrl + '/api/Users/Register', this.state.register)
        .then(response =>{
          if(response && response.status===200)
          {
            if(response.data.Status===201)
            {
                this.setState( { notice : <label style={{ marginLeft: "5px", color:"Green" }}>Đăng ký thành công</label>} );
                //alert("Chúc mừng đăng ký thành công. Tiến hành đăng nhập");
                //window.location.href= window.location.origin + '/login';
            }
            else  
            {
              this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {response.data.Message}</label>} );
              //alert(response.data.Message);
            }
        }}
        ).catch(error =>{
          alert(error);
        })
      }
      onChange =(value)=> {
        // console.log(value );
         let register = this.state.register;
        register["Token"]= value;
        this.setState({register: register});
        //console.log(this.state.register);
      }
      
    render(){
      const {
         Email,
         Password,
         Name ,
         Username,
         Token
      } = this.state.register;
      const notice= this.state.notice;
      const recaptchaRef = React.createRef();
        return (
        <div className="container" style={{ marginTop: "92px" , marginBottom: "20px" , width: "800px" }}>
          <Helmet>
            <title>{configData.CompanyName} - Đăng ký tài khoản</title>
            <meta name="description" content={ configData.Description } />
          </Helmet>
            <form action="/" onSubmit={this.submitHandler}>
              <h1>Đăng ký tài khoản</h1>
              <p>Điền thông tin tài khoản</p>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email</label>
                <input name="Email" required value={Email} onChange={this.changeHandler} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Nhập Email" />
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
              <div className="form-group">
                <label htmlFor="txtName">Họ và tên</label>
                <input name="Name" type="text" required value={ Name } onChange={this.changeHandler} className="form-control" id="txtName" placeholder="Nhập họ tên" />
              </div>
              <div className="form-group">
                <label htmlFor="txtUserName">Tên đăng nhập</label>
                <input name="Username" type="text" required value={ Username } onChange={this.changeHandler} className="form-control" id="txtUserName" placeholder="Nhập họ tên" />
              </div>
              <div className="form-group">
                <label htmlFor="txtPassword">Mật khẩu</label>
                <input name="Password" type="password" required value={Password} onChange={this.changeHandler} className="form-control" id="txtPassword" placeholder="Mật khẩu" />
              </div>
              <div className="form-group">
                <label htmlFor="txtPassword2">Nhập lại mật khẩu</label>
                <input name="Password2" type="password" style={ this.pass2Style } className="form-control" onChange={this.changePass2Handler} id="txtPassword2" placeholder="Mật khẩu" />
              </div>
              <div className="form-group">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  
                  sitekey={ configData.GoogleReCAPTCHA_SiteKey }
                  onChange={this.onChange}
                />
              </div>
              <button type="submit" className="btn btn-primary">Đăng ký</button>
              {notice}
              {/* <button type="button" className="btn btn-primary">Trang chủ</button> */}
            </form>
         </div>   
           
        );
    }

}

export default SignUp;
