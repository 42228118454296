import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../sidebar/sidebar';
import Header from '../sidebar/header';
import { string, any, number } from 'prop-types';

class ViewCommon extends React.Component<any, any> {
    private id: any
    constructor(props:any) {
        super(props);
        this.state = {
          data: []
          

        };
        this.dataListView= this.dataListView.bind(this)
        this.handleDeleteData= this.handleDeleteData.bind(this)
        //this.showPopupEdit= this.showPopupEdit.bind(this)
        
      }
      componentDidMount() {
        fetch('http://saigonit.com/LoadApi',
        {headers:new Headers ({
            "Token": this.state.token,
        })}
        )
            .then(response => response.json())
            .then(data => this.setState({ data }))
            .catch(e => {
                console.log(e);
                return e;
            });
            // this.handleSubmit;
      }
       refreshPage(){ 
        setTimeout(
            function() {
                window.location.reload();
            },300
        );
 
    }
       handleDeleteData(NO_OBJ: any) {     
        return fetch("http://saigonit.com/DeleteCustomerApi?NO_OBJ="+ NO_OBJ, {
          method: 'delete',
          headers:new Headers ({
            "Token": this.state.token,
        })
        }).then(response =>
          response.json().then(json => {
            return json;
          })
        );
      }
        dataListView() {
          return(
            this.state.data.map((item: any, i: any)=>( 
                    <tr key = {item.NO_OBJ} >
                        <th className="w-2" scope="row">{item.STT}</th>
                        <td className="w-20">{item.CustomerCode}</td>
                        <td className="w-20">{item.CustomerName}</td>
                        <td className="w-20">{item.Email}</td>
                        <td className="w-9">{item.Phone}</td>
                        <td className="w-20">{item.Address}</td>
                        <td className="w-10">  
                            <button 
                            className="margin-l-20 btn btn-primary" 
                                        type="button"  
                                        style= {{marginTop: "0px"}}
                                        onClick={
                                            () => {
                                                if(window.confirm("Bạn có chắc chắn muốn xóa khách hàng " + item.CustomerName + " ?"))
                                                {
                                                    this.handleDeleteData(item.NO_OBJ); 
                                                    this.refreshPage();
                                                }
                                            }
                                        }
                                        >
                                <span className="glyphicon glyphicon-trash"></span>
                            </button>
                           &nbsp;&nbsp;
                            <a href="#myModal" role="button" className="btn btn-primary" data-toggle="modal"  
                            onClick={ () => { this.setState( { detailKey : item.NO_OBJ }) } }>
                            <span className="glyphicon glyphicon-pencil"></span>                 
                            </a>
                        </td>
                    </tr>
                )
            )
          ) 
    }

    render(){
        const styleshow = {
            display: this.state.show ? "block" : "none"
        }
        let show=false;
        
        const handleClose = () => { show=false; };
        const handleShow = () => { show=true; };
        //console.log(this.state.data)
        return (
            <div className="card text-left">
              <img className="card-img-top" src="holder.js/100px180/" alt=""/>
              <div className="card-body">
                <h4 className="card-title">Title</h4>
                <p className="card-text">Body</p>
              </div>
            </div>
        );
    }

}
export default ViewCommon;
