import React from 'react';

class AdminNavbar extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
	}
	componentDidMount() {
      }
	componentWillMount(){
	}
    render(){
        return (
            <div  >
                
            </div>
            
        );
    }
}
export default AdminNavbar;
