import React from 'react';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from '../../config.json';
import ReCAPTCHA from "react-google-recaptcha";
import UrlService from '../../services/UrlService';
import {Helmet} from 'react-helmet'; 

class LossPassSuccess extends React.Component<any, any> {
  private dateValue:Date=new Date();
  private pass2Style;
    constructor(props: any) {
        super(props);
        this.state = {
          loss : {
            Email: '',
            Token:''
          },
            notice: any
        };
        
    }
    render(){
        return (
        <div className="container" style={{ marginTop: "90px" , minHeight:"70vh", marginBottom: "20px" , width: "800px" }}>
          <Helmet>
            <title>{configData.CompanyName} - Khôi phục mật khẩu</title>
            <meta name="description" content={ configData.Description } />
          </Helmet>
            <div className="card">
                <h5 className="card-header">Khôi phục mật khẩu</h5>
                <div className="card-body">
                    <p className="card-text">Chúng tôi đã gửi một email tới địa chỉ email đăng ký của bạn. Vui lòng kiểm tra hộp thư và nhấn vào link liên kết để hoàn tất quá trình khôi phục mật khẩu.</p>
                </div>
            </div>
         </div>   
        );
    }
}
export default LossPassSuccess;
