import React from 'react';
import '../home/stylePage.css'; 
import queryString from 'query-string';
import {  Container, Row,Col } from 'react-bootstrap';

import ProductViewList from '../Commons/ProductViewList';
import ContentView from '../Commons/ContentView';
import BreadCrumbs from '../Commons/BreadCrumbs';
import Products from './Products';
import Product from './Product';
import {Helmet} from 'react-helmet'; 
import configData from "../../config.json";


class SanPham extends React.Component<any, any> {
              
          constructor(props:any) {
              super(props);
              this.state = {
                ID_OTY: 1,
                ID_OGR: 0,
                NO_OBJ: 0
              }
          }
          componentDidMount() {
              const values = queryString.parse(this.props.location.search);
              const query= queryString.parse(window.location.search);
              this.setState({
                ID_OGR: query.m,
                NO_OBJ:query.id
              });
            }

          componentWillMount(){
          
          }


          render(){

            const div1 = {
              paddingLeft : '10px'
          
            }  as const;
            
            let rs;
            if(this.state.NO_OBJ ===0 || this.state.NO_OBJ===null || this.state.NO_OBJ===undefined)
            {
              // rs= <ProductViewList ID_OTY={this.state.ID_OTY} ID_OGR={this.state.ID_OGR} PageIndex="1" RowPerPage="10" ></ProductViewList>;
              rs= <Products/>
            }
            else 
            {
              // rs=<ContentView ID_OTY={this.state.ID_OTY} NO_OBJ={this.state.NO_OBJ} ></ContentView>;
              rs= <Product/>
            }
            return (
              <div>
                 <Helmet>
                  <title>{configData.CompanyName+ " - Sản phẩm"} </title>
                  <meta name="description" content={ configData.Description + ", Sản phẩm"} />
              	</Helmet>
                 <section className="inner-page">
                  <div>
                      <BreadCrumbs ></BreadCrumbs>
                  </div>
                    <div className="_container-flud _body_home" >
                      {rs}
                    </div>
                </section>
              </div>
              );
          }

}

export default SanPham;
