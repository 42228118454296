import React from 'react';
import '../TinTuc/News.css';
import ImageView from '../Commons/ImageView';

class NewsItem extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
	}
	componentDidMount() {
        
      }
	componentWillMount(){
	}
    render(){

		 
		const img1 = {
			width : '100%',
			
			
			dataLlStatus : 'loaded'
		  }  as const;

        return (
			<div className="post-box" >
				<div className="post-inner">
						
					<div className="entry-media">
						<div className="post-cat">
							<span className="posted-in">
								<a href={"/"+this.props.Module + "/?m=" + this.props.item.ID_OGR} rel="category tag">{this.props.item.NAME_OGR}</a>
							</span>
						</div>
						<a href={this.props.item.Url} >
						<ImageView src={this.props.item.ImageUrl} className="attachment-post-thumbnail size-post-thumbnail wp-post-image lazyloaded" style={img1} />
						</a>
					</div>


					<div className="inner-post">
						<div className="entry-header">

								<div className="entry-meta">
								<span className="posted-on"><a href={this.props.item.Url} rel="bookmark"><time className="entry-date published"  >{this.props.item.NgayDang}</time></a></span>
								</div> 
							
							<h3 className="entry-title"><a href={this.props.item.Url} rel="bookmark">{this.props.item.NAME_OBJ}</a></h3>
						</div> 

						<div className="entry-summary the-excerpt">

							<p>{this.props.item.NOTE}...</p>

						</div> 
						<div className="btn-readmore">
							<a href={this.props.item.Url}><i className="flaticon-right-arrow-1"></i> XEM THÊM</a>	 
						</div>
					</div>
				</div>
			</div>
        );
    }
}
export default NewsItem;

