import CookieService from "./CookieService";
class AccountService {
    Token()
    {
        return CookieService.get("access_token");
    }
    Roles()
    {
        return CookieService.get("Roles");
    }
  }
  export default new AccountService();