import React from 'react';
 

class ImageView extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
        this.state = {
            src: props.src,
            errored: false,
            fallbackSrc:"../assets/img/NoImg.jpg"
        };
	}
	componentDidMount() {
        
      }
	componentWillMount(){
    
	}
  componentWillReceiveProps(props) {
    this.setState({
      src: this.props.src
    });
  }
    onError = () => {
        if (!this.state.errored) {
          this.setState({
            src: this.state.fallbackSrc,
            errored: true,
          });
        }
      }

    render(){
     
        return (
	     
         <img src={this.state.src} className={ this.props.className } alt= { this.props.alt } style={this.props.style}  onError={this.onError}/>
        
        );
    }

}

export default ImageView;
