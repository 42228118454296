import React from 'react';
import configData from "../../config.json";
import LastNewsMaster from '../TrangChu/LastNewsMaster';
import LastNewsFullWidth from './LastNewsFullWidth';
import Slider from "./Slider";
import {Helmet} from 'react-helmet'; 
import Banner from './Banner';

class HomeBody extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
	}
	componentDidMount() {
      }
	componentWillMount(){
	}
    render(){
        return (
            <div className="container-fluid ml-0 mr-0 pl-0 pr-0" style={{ marginTop:"85px" }}  >
                <Helmet>
					<title>{configData.CompanyName} - {configData.Sologan}</title>
					<meta name="description" content={ configData.Description } />
            	</Helmet>
                 <section id="hero" className="inner-page pb-0 pt-0">
                     <Banner></Banner>
                 </section>
                 <section   className="inner-page pb-0 pt-0">
                    
                    <LastNewsMaster ID_OTY={configData.LastNewsModule}/> 
                 </section>
             </div>
        );
    }
}
export default HomeBody;
