import React from 'react';
import UrlService from '../../services/UrlService';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'; 

class ProductBody extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
		}
	}
	componentDidMount() {
      }

	componentWillMount(){
		 
	}

    render(){
        return (
			<section className="container-flud">
                <div className="container">
                    <div className="elementor-widget-container elementor-widget-wrap">
                        <div className="elementor-text-editor elementor-clearfix text-justify">
                            { ReactHtmlParser(this.props.Content) }
                    	</div>
                    </div>
                </div>
            </section>
        );
    }

}

export default ProductBody;
