class UrlService {
    UrlRoot()
    {
      return window.location.protocol + "//" + window.location.host ;//+ ((window.location.port==="0" || window.location.port==="" || window.location.port==="80") ? "" : ":"+ window.location.port);
    }
    UrlCurent()
    {
      return window.location.href;
    }
  }
  export default new UrlService();