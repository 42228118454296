import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './SignUp.css';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from "../../../config.json";

import {Helmet} from 'react-helmet';
import { Button } from 'react-bootstrap';
import CookieService from '../../../services/CookieService';

class GlobalConfig extends React.Component<any, any> {
  private dateValue:Date=new Date();
    constructor(props: any) {
        super(props);
        this.state = {
          ID: 'ImgBanner',
          imageUrl: '',
          selectedFile: null
        };
        
    }

    componentDidMount() {
      fetch(
        configData.serviceUrl+ '/api/Common/GetConfig?ID_INF=99012',
        {headers:new Headers ({
            'Access-Control-Allow-Origin' : '*'
        })}
    
    ) 
        .then(response => response.json())
        .then(dataJs => {
            //console.log(dataJs);
            if( dataJs.Data.length>0)
            {
              var listOrderJson = JSON.stringify(dataJs.Data);
              JSON.parse(listOrderJson).forEach((item, index) => { 
                if(item.ID==="ImgBanner")
                {
                  this.setState({
                    imageUrl: item.VALUE
                  })
                }
             } );
            }
            
        })
        .catch(e => {
            console.log(e);
        });
    }
    

    submitHandler = (e: any) =>{
      e.preventDefault();
      
      
      fetch(
          configData.serviceUrl+ '/api/Admin/SaveConfig?ID_INF=99012',
          {
              method: 'POST' ,
              body: "[ { \"ID\": \"ImgBanner\", \"VALUE\": \"" + this.state.imageUrl + "\" } ]",
              headers: new Headers ({
              'Access-Control-Allow-Origin' : '*',
              'Authorization' :  CookieService.get("access_token") ,
              'Content-Type' : 'application/json'
              })
              
          }
      ) 
          .then(response => response.json())
          .then(rss => {
            if(rss.Status===200 || rss.Status===201)
            {
              window.location.reload();
            }
            else
            {
                alert("Có lỗi: " + rss.Message);
            }
          })
          .catch(e => {
              console.log(e);
          });
      }


      onFileUpload = () => {
        const formData = new FormData();
        // Update the formData object
        formData.append(
        "files",
        this.state.selectedFile
        );
        // Details of the uploaded file
        console.log(this.state.selectedFile);
        // Request made to the backend api
        // Send formData object
        fetch(
            configData.serviceUrl+ '/api/Admin/Upload?ModunName=TrangChu',
            {
                method: 'POST' ,
                body: formData,
                headers:new Headers ({
                'Access-Control-Allow-Origin' : '*',
                'Authorization' : CookieService.get("access_token") 
               
                })
            }
        
        )
        .then(response => response.json())
        .then(rss => {
            if(rss.Status===200)
            {
                this.setState({imageUrl: rss.Data.NameFile});
                alert("Upload xong!");
            }
            else
            {
                alert("Upload lỗi: " + rss.Message);
            }
        })
        .catch(e => {
            console.log(e);
        });
            
           
    };
    onFileChange = event => {
    
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
    
    };
    fileData = () => {
            
      if (this.state.selectedFile) {
      
      return (
          <div>
          <h2>Chi tiết file:</h2>
                  
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          </div>
           );
      } 
      else
       {
           return (
                  <div>
                  <br />
                  <h4>Chọn file để upload</h4>
                  </div>
              );
       }
  };
    render(){
      const img1 = {
        width : '130px',
        height : '105px',
        resize : 'both'
      }  as const;
     
       
      
        return (
        <div className="container" style={{ marginTop: "10px" , marginBottom: "10px" , width: "100%" }}>
          <Helmet>
            <title>{configData.CompanyName} - Thiết lập chung</title>
            <meta name="description" content={ configData.Description } />
          </Helmet>
            <form action="/" onSubmit={this.submitHandler}>
              <h1>Thiết lập chung</h1>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Hình ảnh Banner</label>
                <img src={"/Upload/TrangChu/"+this.state.imageUrl} style={img1}></img> 
                            <br/>
                            <br/>
                            <div>
                                    <input type="file" onChange={this.onFileChange} />
                                    <Button   onClick={this.onFileUpload}>
                                    Upload...
                                    </Button >
                            </div>
                            {this.fileData()}
              </div>
              <button type="submit" className="btn btn-primary">Lưu</button>
              
            </form>
         </div>   
           
        );
    }

}

export default GlobalConfig;
