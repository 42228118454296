import React from 'react';
import './App.css';
import AdminHome from './component/Admin/AdminHome';
import TrangChu from './component/TrangChu/TrangChu';
 

class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }
  render(){
    let rt= window.location.pathname.toString().startsWith("/Admin")? <AdminHome /> : <TrangChu />
    return (
        rt
    );
  }
  //trieu checkin code
}

export default App;