import React from 'react';
import configData from "../../config.json";
import ProjectFilter from "../SanPham/ProjectFilter";
import ProductsList from './ProductsList';
import ProductsHeader from './ProductsHeader';
import { Col, Row } from 'react-bootstrap';
import UrlService from '../../services/UrlService';
import ShareSocial from '../Commons/ShareSocial';

 

class ProductHeader extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
		}
	}
	componentDidMount() {
      }

	componentWillMount(){
		 
	}
    

    render(){
        return (
			<section className="container-flud">
                <div className="container">
                    <Row>
                        <div className="col-xl-3 elementor-widget-wrap">
                            <div className="elementor-element elementor-element-19c45b8d elementor-widget elementor-widget-heading" data-id="19c45b8d" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container text-left mt-0 mb-0">
                                    <span className="elementor-heading-title elementor-size-default">Mã sản phẩm:</span>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-2c1f83f1 elementor-widget elementor-widget-heading" data-id="2c1f83f1" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container text-left mt-0 mb-0">
                                    <span className="elementor-heading-title elementor-heading-title-content">{ this.props.Published }</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 elementor-widget-wrap">
                            <div className="elementor-element elementor-element-19c45b8d elementor-widget elementor-widget-heading" data-id="19c45b8d" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container text-left mt-0 mb-0">
                                    <span className="elementor-heading-title elementor-size-default">Danh mục:</span>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-2c1f83f1 elementor-widget elementor-widget-heading" data-id="2c1f83f1" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container text-left mt-0 mb-0">
                                    <span className="elementor-heading-title elementor-heading-title-content">{this.props.Category}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 elementor-widget-wrap">
                            <div className="elementor-element elementor-element-19c45b8d elementor-widget elementor-widget-heading" data-id="19c45b8d" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container text-left mt-0 mb-0">
                                    <span className="elementor-heading-title elementor-size-default">Tên sản phẩm:</span>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-2c1f83f1 elementor-widget elementor-widget-heading" data-id="2c1f83f1" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container text-left mt-0 mb-0">
                                    <span className="elementor-heading-title elementor-heading-title-content">{this.props.Client}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 elementor-widget-wrap">
                            <ShareSocial ImageUrl={this.props.ImageUrl} MailSubject={ this.props.Client } MailBody={ this.props.Client } />
                        </div>
                    </Row>
                </div>
            </section>
        );
    }

}

export default ProductHeader;
