import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './SignUp.css';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from '../../config.json';
import queryString from 'query-string';
import UrlService from '../../services/UrlService';

class Active extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
          loss : {
            email: '',
            reset:''
          },
            notice: any,
            buttonLogin: any
        };
        
    }
    componentDidMount() {
        const query= queryString.parse(window.location.search);
        let loss = this.state.loss;
        loss["reset"] = query.reset;
        loss["email"] = query.e;
        this.setState({loss : loss});
        console.log(this.state.loss);
        axios
        .post(configData.serviceUrl + '/api/Users/ConfirmPassword', this.state.loss)
        .then(response =>{
        if(response && response.status===200)
        {
            if(response.data.Status===202)
            {
                this.setState( { notice : <p className="card-text" style={{ color:"green" }} >{response.data.Message}</p> });
                this.setState( { buttonLogin : <a href="/DangNhap" className="btn btn-primary">Đăng nhập</a>});
            }
            else
            {
                this.setState( { notice : <p className="card-text" style={{ color:"red" }} >{response.data.Message}</p>  });
            }
        }
        else
        {
            this.setState( { notice : "Lỗi kết nối API" });
        }
    }
        ).catch(error =>{
          alert(error);
        })
      }
    render(){
      const notice= this.state.notice;
      const buttonLogin= this.state.buttonLogin;
        return (
            <div className="container" style={{ marginTop: "90px" , minHeight:"70vh", marginBottom: "20px" , width: "800px" }}>
            <div className="card">
                <h5 className="card-header">Xác nhận khôi phục mật khẩu</h5>
                <div className="card-body">
                    {notice}
                    {buttonLogin}
                </div>
            </div>
         </div>   
        );
    }

}

export default Active;
