import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Link, Route, Switch, BrowserRouter } from 'react-router-dom';
 
import RouterUrl from '../Commons/RouterUrl';
import AdminNavbar from "./AdminNavbar";
import Sidebar from "./Sidebar";

import Footer from "./Footer";

import routes from "./routes";
import sidebarImage from "./assets/img/sidebar-4.jpg";


 
//import "./assets/css/animate.min.css";
//import "./assets/css/light-bootstrap-dashboard-react.css";
import "./assets/css/adminhome.css";
//import "./assets/css/demo.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";


class AdminHome extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			showLogin: false,
			ID_OTY: false,
			checkLogin1: false,
			checkLogin2: true
		}
	}

	componentDidMount() {
	}
	componentWillMount(){
		let num : any
		num  = 1
		if( sessionStorage.getItem('Token') ===  null){
			this.setState({showLogin: true})
			this.setState({checkLogin1: false})
			this.setState({checkLogin2: true})
		}else{
			this.setState({checkLogin1: true})
			this.setState({checkLogin2: false})
		}
		if( sessionStorage.getItem('ID_OTY') == num ){
			this.setState({ID_OTY: true})
		}
		//window.location.reload();
	}
	checkLogin(){
		if(sessionStorage.getItem('Token')===null){
			return <Link to="login"></Link>
		}else {
			 return <button data-toggle="modal" data-target="#myModalAdd" type="button" 
                    ></button>
		}
	}
	logOut(){
		sessionStorage.clear();	
	}

    render(){
		
		const image = sidebarImage;
		const color ="blue";
		const hasImage =true;

		const getRoutes = (routes) => {
			return routes.map((prop, key) => {
			  if (prop.layout === "/Admin") {
				return (
				  <Route
					path={prop.path}
					component={prop.component}
					key={key}
				  />
				);
			  } else {
				return null;
			  }
			});
		  };
	   
		 
		  
        return (
           
		 <>
			 <div className="adminwrapper">
				 
			   <div>
			      <Sidebar  color={color} image={hasImage ? image : ""} routes={routes} />
			   </div>

			   <div className="main-panel" >
				 <AdminNavbar />
				 <div className="content">
				   <BrowserRouter>
				   <Switch>{getRoutes(routes)} </Switch>
				   </BrowserRouter>
				 </div>
				 <Footer />
			   </div>
			 </div>
		   </>
        );
    }

}

export default AdminHome;
