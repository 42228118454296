import React from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import configData from "../../config.json";
import { Container, Row } from 'react-bootstrap';
import LastNews from './LastNews';
import LastNewsFullWidth from './LastNewsFullWidth';
import ImageView from '../Commons/ImageView';

class Banner extends React.Component<any, any> {
	constructor(props:any) {
        super(props);
        this.state = {
            src: "assets/img/hero-img.png",
        };
	}
	componentDidMount() {
        fetch(
            configData.serviceUrl+ '/api/Common/GetConfig?ID_INF=99012',
            {headers:new Headers ({
                'Access-Control-Allow-Origin' : '*'
            })}
        
        ) 
            .then(response => response.json())
            .then(dataJs => {
                //console.log(dataJs);
                if( dataJs.Data.length>0)
                {
                  var listOrderJson = JSON.stringify(dataJs.Data);
                  JSON.parse(listOrderJson).forEach((item, index) => { 
                    if(item.ID==="ImgBanner" && item.VALUE.length>0)
                    {
                      this.setState({
                        src: "Upload/TrangChu/"+item.VALUE
                      })
                    }
                 } );
                }
                
            })
            .catch(e => {
                console.log(e);
            });
      }
	componentWillMount(){
	}
    render(){
        return (
            <div className="container">
               {/* <ImageView src="/img/banner.jpg" /> */}

               <div className="row">
                    <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                    <h1>Công ty Cổ phần Tin học Sài Gòn</h1>
                    <h2>Đơn giản, chuyên nghiệp, hiệu quả</h2>
                    <div className="d-lg-flex">
                        <a href="/LienHe" className="btn-get-started scrollto">Tư vấn & Báo giá</a>
                        
                    </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                      <img src={this.state.src} className="img-fluid animated" alt="" />
                    </div>
                </div>
            </div>
        );
    }

}

export default Banner;
