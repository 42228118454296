import React from 'react';
import CookieService from '../../services/CookieService';
import UrlService from '../../services/UrlService';
import configData from '../../config.json';
import { Link } from 'react-router-dom';


class Footer extends React.Component<any, any> {
	constructor(props:any) {
        super(props);
	}
	componentDidMount() {
      }
	componentWillMount(){
	}
    Logout=()=>{
        CookieService.remove("access_token");
        window.location.href= UrlService.UrlRoot();
    }
    render(){
        const Token= CookieService.get("access_token");
        let Account;
        if(Token)
        {
            Account= <div> <span>Xin chào, { CookieService.get("DisplayName") } |  <a href="#" onClick ={()=>this.Logout()}>Đăng xuất</a></span></div>
        }
        else
        {
            Account=<span> <a href="../DangNhap">Đăng nhập</a> | <a href="../DangKy">Đăng ký</a></span>
        }
        return (
            <footer id="footer" className="mt-auto">
                <div className="container footer-bottom">
                <div className="copyright">
                    <strong><span>{configData.Copyrights}</span></strong>
                </div>
               
                </div>
            </footer>
        );
    }
}
export default Footer;
