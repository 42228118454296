import React from 'react';
import configData from "../../../config.json";
import CookieService from '../../../services/CookieService';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';
import { Button } from 'react-bootstrap';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import '../../home/styleAdminPage.css';
import TreeListObjectGroup from '../Share/TreeListObjectGroup'
import ChiTietSanPham from './ChiTietSanPham'

class DanhSachSanPham extends React.Component<any, any> {
	node: any;
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
			groupJs: [],
			ID_OTY: 0,
			ID_OGR: 0
		}
	}
	componentDidMount() {
         
        fetch(
			configData.serviceUrl+ '/api/Admin/GetObject?ID_OTY='+ this.props.ID_OTY ,
			{headers:new Headers ({
				'Access-Control-Allow-Origin' : '*',
                'Authorization' : CookieService.get("access_token") 
			})}
        
        ) 
		    .then(response => response.json())
            .then(dataJs => {
				this.setState({
					dataJs: dataJs.Data
				});
			})
            .catch(e => {
                console.log(e);
				
               
            });

			fetch(
				configData.serviceUrl+ '/api/Admin/GetObjectGroupList?ID_OTY='+ this.props.ID_OTY ,
				{headers:new Headers ({
					'Access-Control-Allow-Origin' : '*',
					'Authorization' : CookieService.get("access_token") 
				})}
			
			) 
				.then(response => response.json())
				.then(groupJs => {
					this.setState({
						groupJs: groupJs.Data
					});
				})
				.catch(e => {
					console.log(e);
					
				   
				});
      }

	componentWillMount(){
		
	}

	handleAddRow = () => {
		 
		var row = {
			NO_OBJ: 0,
			ID_OTY: this.props.ID_OTY,
			ID_OBJ:"",
			NAME_OBJ: " ",
			SHORTNAME: "",
			NOTE: "",
			VISIBLE: true,
			NO_OBJP: null,
			ID_OGR: null,
			NAME_OGR:"",
			NO_OBJ1: null,
			NO_OBJ2: null,
			NO_OBJ3: null,
			NO_OBJ4: null,
			NO_OBJ5: null,
			TEXT1: "",
			TEXT2: "",
			TEXT3: "",
			TEXT4: "",
			TEXT5: "",
			TEXT7:"",
			DATE1: null,
			DATE2: null,
			DATE3: null,
			DATE4: null,
			DATE5: null,
			INT1: null,
			INT2: null,
			MONEY1: null,
			MONEY2: null,
			BOOL1: null,
			BOOL2: null,
			CODE: ""
		}
		this.state.dataJs.push(row);
		this.setState({
			dataJs: this.state.dataJs
		});
	}

	handleDeleteData = () => {
		try{
           if(this.node.selectionContext.selected.length>0)
		   {
			var result = window.confirm("Có đồng ý xóa "+ this.node.selectionContext.selected.length + " dòng đang chọn?");
			if (result) {
			    
				 this.node.selectionContext.selected.forEach(element => 
					{
						try{
						 
							fetch(
								configData.serviceUrl+ '/api/Admin/ObjectDetail',
								{
									method: 'DELETE' ,
									body: '{ "NO_OBJ": __VL__ }'.replace("__VL__",element),
									headers: new Headers ({
									'Access-Control-Allow-Origin' : '*',
									'Authorization' :  CookieService.get("access_token") ,
									'Content-Type' : 'application/json'
									})
								}
							) 
								.then(response => response.json())
								.then(dataJs => {
									//console.log(dataJs);
									window.location.reload();
								})
								.catch(e => {
									console.log(e);
									
								});

						}
						catch(err) {
							console.log("Lỗi xóa: "+err.message);
						}
					}
					);
                   
					//window.location.reload();
			}
			
		   }
		}
		catch(err2) {
			console.log("Lỗi xóa dữ liệu: "+err2.message);
		}
	}

    render(){
        
		const btn1 = {
			width : '100px',
            marginBottom : '5px'
		  }  as const;
		  const btn2 = {
			width : '100px',
			marginLeft : '5px',
            marginBottom : '5px'
		  }  as const;
          const btn3 = {
			width : '200px',
			marginLeft : '5px',
            marginBottom : '5px'
		  }  as const;

		const headerSortingStyle = { backgroundColor: '#c8e6c9' };

		const columns = [
            {
				dataField: 'ID_OBJ',
				text: 'Mã hàng',
				sort: true,
				headerSortingStyle,
				filter: textFilter()
			},
            {
			dataField: 'NAME_OBJ',
			text: 'Tên hàng',
			sort: true,
			headerSortingStyle,
            filter: textFilter()
		  },
		  {
			dataField: 'NAME_OGR',
			text: 'Danh mục',
			sort: true,
			headerSortingStyle,
            filter: textFilter()
		  }
		  ,{
			dataField: 'NOTE',
			text: 'Mô tả'
		  }
		  ,{
			dataField: 'VISIBLE',
			text: 'Hiển thị?',
			sort: true,
			type: 'bool',
			editor: {
				type: Type.CHECKBOX,
				value: 'true:false'
			  }
		  }
		];

		const defaultSorted = [{
			dataField: 'ID_OBJ' 
		  }];

		  const expandRow = {
			showExpandColumn: true,
			renderer: row => (
			  <div>
				  <ChiTietSanPham NO_OBJ={row.NO_OBJ} row={row} groupJs={this.state.groupJs} ModunName={this.props.ModunName}/>
				 
			  </div>
			)
		  };

        return (
				<div id="div_list_content" className="yaallahaa-news-grids-agile">
					<Button variant="primary" style={btn1} onClick={this.handleAddRow} >Thêm</Button>
					<Button variant="primary" style={btn3}  onClick={ this.handleDeleteData }>Xóa dòng đang chọn</Button>
					 
                     <BootstrapTable
					    ref={ n => this.node = n }
						keyField='NO_OBJ' 
						data={this.state.dataJs} 
						columns={ columns } 
						hover
						condensed
						noDataIndication="Chưa có dữ liệu"
						selectRow={ { mode: 'radio', clickToSelect: true , clickToExpand: true } }
						defaultSorted={ defaultSorted } 
                        filter={ filterFactory() }
                        pagination={ paginationFactory() }
                        wrapperClasses="table-responsive"
						expandRow={ expandRow }
					 />
				</div>
        );
    }

}

export default DanhSachSanPham;
