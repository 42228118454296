import React from 'react';
import UrlService from '../../services/UrlService';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CookieService from '../../services/CookieService';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import {  Container, Row,Col } from 'react-bootstrap';
import { TiDeleteOutline } from 'react-icons/ti';
import BreadCrumbNews from '../Commons/BreadCrumbNews';
import '../GioHang/GioHang.css'; 
import axios from 'axios';
import configData from '../../config.json';
import CurrencyFormat from 'react-currency-format';
import {Helmet} from 'react-helmet'; 
import BreadCrumbs from '../Commons/BreadCrumbs';

class GioHang extends React.Component<any, any> {
	node: any;
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
		}
	}
	componentDidMount() {
		
      }
	componentWillMount(){
		if(localStorage && localStorage.getItem("orderData")!==null)
		{
			var orderData=localStorage.getItem("orderData");
			var dataCookie= JSON.parse(orderData ? orderData : "");
			this.setState({ dataJs : dataCookie});
		}
	}
	deleteProduct=()=>
	{
		console.log("Test xóa");
	};
	addOrder=()=>{
        axios
        .post(configData.serviceUrl + '/api/Admin/AddOrder', this.state.dataJs,
		{headers: {
			'Access-Control-Allow-Origin' : '*',
			'Authorization' :  CookieService.get("access_token") ,
			'Content-Type' : 'application/json',
			'accept': 'application/json'
			}})
        .then(response =>{
          if(response && response.status===200)
          {
            if(response.data.Status===201)
            {
                alert("Thêm đơn hàng thành công!");
				if(localStorage && localStorage.getItem("orderData")!==null)
				{
					localStorage.removeItem("orderData");
				}
                window.location.href= UrlService.UrlRoot()+"/DonHang";
            }
            else  
            {
              alert(response.data.Message);
            }
        }}
        ).catch(error =>{
          alert(error);
        })
    }
	backProduct=()=>{
		window.location.href= UrlService.UrlRoot()+"/SanPham";
    }
	// addOrder2=()=>{
	// 	console.log(JSON.parse(this.state.dataJs));
	// 	console.log(CookieService.get("access_token"));
    //     try{
				 
	// 		fetch(
	// 			configData.serviceUrl + '/api/Admin/AddOrder',
	// 			{
	// 				method: 'POST' ,
	// 				body: JSON.parse(this.state.dataJs),
	// 				headers: new Headers ({
	// 				'Access-Control-Allow-Origin' : '*',
	// 				'Authorization' :  CookieService.get("access_token"),
	// 				'Content-Type' : 'application/json'
	// 				})
					
	// 			}
	// 		) 
	// 			.then(response => response.json())
	// 			.then(dataJs => {
	// 				console.log(dataJs);
	// 				alert("Thêm đơn hàng thành công!");

	// 			})
	// 			.catch(e => {
	// 				console.log(e);
	// 			});
	// 	}
	// 	catch(err) {
	// 		console.log("Lỗi lưu: "+ err);
	// 	}
    // }
	actionsFormatter (cell, row ,rowIndex, {parents})
	{
		return <ul className="list-inline m-0">
	
		<li className="list-inline-item">
			<button className="btn btn-danger btn-sm rounded-0" onClick={ () => 
			{
				console.log(parents);
				let orderData=localStorage.getItem("orderData")? localStorage.getItem("orderData") : "";
				if(orderData)
				{ 
					var orderDataNew= JSON.parse(orderData);
					orderDataNew.splice(0,1);
					//console.log(orderDataNew);
					localStorage.setItem("orderData", JSON.stringify( orderDataNew));
					window.location.href =window.location.href;
				}
			}
		}
			type="button" data-toggle="tooltip" data-placement="top" title="Delete">
			<i className="fa fa-trash"></i>
			</button>
		</li>
	</ul>
	}
    render(){
		const dataJs= this.state.dataJs;
		let total=0, quantity=0;
		dataJs.forEach((item, index) => { 
                    total+=item.total;
					quantity+= item.quantity;
                 } );

		function priceFormatter(cell, row) {
			return (
			  <span>{parseInt(cell).toLocaleString() }</span>
			);
		}
		 
        const columns = [
			{
                dataField: 'code',
                text: 'Mã sản phẩm',
                sort: true,
				editable: false
            },
            {
			dataField: 'name',
			text: 'Tên sản phẩm',
			editable: false
			}
			,{
				dataField: 'quantity',
				text: 'Số lượng',
				type: 'number',
				editorStyle: {
					backgroundColor: '#007bff',
					color:'#f6f6f6'
				  },
				validator: (newValue, row, column) => {
					if (isNaN(newValue)) {
					  return {
						valid: false,
						message: 'Chưa nhập số lượng'
					  };
					}
					if (newValue <= 0) {
					  return {
						valid: false,
						message: 'Chưa nhập số lượng'
					  };
					}
					return true;
				  }
			} 
			,{
				dataField: 'price',
				text: 'Đơn giá',
				editable: false,
				formatter: priceFormatter
			}
			,{
				dataField: 'total',
				text: 'Thành tiền',
				editable: false,
				formatter: priceFormatter
			}
			,{
				dataField: 'actions',
				text: 'Thao tác',
				isDummyField: true,
				csvExport: false,
				formatter: this.actionsFormatter,
				formatExtraData: { parents: this.state.dataJs },
				editable: false
			  }
            
		];

	 
		  const rowStyle={ height: "50px"  };
		  

		  function afterSaveCell(oldValue, newValue, row, column) {
			console.log(row);
			console.log(column);
			try{
				 
				
			}
			catch(err) {
				console.log("Lỗi lưu: "+err.message);
			}
		}

        return (
			<div className="_container"  >
				<Helmet>
                  <title>{configData.CompanyName + " - Giỏ hàng"} </title>
                  <meta name="description" content={ configData.Description + ", Giỏ hàng"} />
              	</Helmet>
			<section className="inner-page">
			   {/* <div>
				<BreadCrumbNews backgroundImage="../assets/img/bg-pheader.jpg"></BreadCrumbNews>
			   </div> */}
			   <div>
                      <BreadCrumbs ></BreadCrumbs>
                  </div>
			   <div style={{paddingTop: '90px', paddingBottom:'110px'}}>
	  
			   <Container >
				   <Row  >
					 <Col sm={12} lg={8} md={12}> 
						<BootstrapTable
							ref={ n => this.node = n }
							keyField='code' 
							data={dataJs} 
							columns={ columns } 
							hover
							condensed
							noDataIndication="Chưa có dữ liệu"
							cellEdit={ cellEditFactory({
								mode: 'click',
								autoSelectText: true,
								blurToSave: true
								,afterSaveCell
							}) }
						
							filter={ filterFactory() }
					
							wrapperClasses="table-responsive"
							rowStyle={ rowStyle }
							//rowEvents={ rowEvents }
							// expandRow={ expandRow }
						/>
					 </Col>
					 <Col sm={12} lg={4} md={12}>
					 <div className="card">
						<div className="card-header">
						CỘNG GIỎ HÀNG
						</div>
						<div className="card-body">
							<Container className="cart-subtotal">
									<Row>
										<Col className="subtotalLeft">Số lượng</Col>
										<Col className="subtotal">{quantity}</Col>
									</Row>
									<Row>
										<Col className="subtotalLeft">Tổng tiền</Col>
										<Col className="subtotal"> <CurrencyFormat value={total} displayType={'text'} thousandSeparator={true}  suffix={'đ'} /></Col>
									</Row>
									<Row style={ { paddingTop: "10px"}}>
										<Col>
											<Button className="checkout-button" variant="primary" style={{width: '100%'}} onClick ={()=>this.addOrder()}>Đặt hàng</Button>
										</Col>
										<Col>
											<Button className="checkout-button" variant="primary" style={{width: '100%'}} onClick ={()=>this.backProduct()}>Quay lại</Button>
										</Col>
									</Row>
								</Container>
								
								
						</div>
					</div>
					   {/* <Card className="cart_totals"  >
							<Card.Body>
								<Card.Title>CỘNG GIỎ HÀNG</Card.Title>
								<Container className="cart-subtotal">
									<Row>
										<Col className="subtotalLeft">Số lượng</Col>
										<Col className="subtotal">{quantity}</Col>
									</Row>
									<Row>
										<Col className="subtotalLeft">Tổng tiền</Col>
										<Col className="subtotal"> <CurrencyFormat value={total} displayType={'text'} thousandSeparator={true}  suffix={'đ'} /></Col>
									</Row>
								</Container>
								 
								<Button className="checkout-button" variant="primary" style={{width: '100%'}} onClick ={()=>this.addOrder()}>Đặt hàng</Button>
							</Card.Body>
							</Card> */}
					   </Col>	
				   </Row>
			   </Container>
			   </div>
			 </section>
		 </div>
			 
        );
    }

}

export default GioHang;
