import Cookie from "universal-cookie";

const cookie = new Cookie();

class CookieService {
  get(key: string) {
    return cookie.get(key);
  }

  set(key: string, value: string, options: object) {
    cookie.set(key, value, options);
  }
  setForever(key: string, value: string) {
    let expires = new Date();
    let options= { path: '/',  expires};
    expires.setTime(expires.getTime() + 2147483647);
    cookie.set(key, value, options);
  }
  
  remove(key: string) {
    cookie.remove(key);
  }
}

export default new CookieService();