import React from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import configData from "../../config.json";
import { Container, Row } from 'react-bootstrap';
import ImageView from '../Commons/ImageView';

class LastNewsFullWidth extends React.Component<any, any> {
	constructor(props:any) {
        super(props);
		this.state = {
			lstContent :[
				{
				  ID_OBJ: "",
				  NAME_OBJ: "",
				  NO_OBJ: 0,
				  ORDER_OGR: 0,
				  NAME_OGR: "",
				  HTML: "",
				  TEXT1: "",
				  ID_OTY: 0,
				  Title: "",
				  LINK_GROUP: "",
				  LINK_NEW: "",
				  NAME_FUN: "",
				  ImageThumbUrl: "",
				  ImageUrl: "",
				  Ngay: "2021-07-26T04:07:30.408Z",
				  NgayDang: "",
				  Subs: [
					
				  ],
				  ID_OGR: 0,
				  DATE1: "2021-07-26T04:07:30.408Z"
				}
			  ],
			showLogin: false,
			ID_OTY: false,
			checkLogin1: false,
			checkLogin2: true
		}
	}
	componentDidMount() {
        fetch(configData.serviceUrl + '/api/Common/GetBoxOther?ID_OTY='+ this.props.ID_OTY + '&ID_OGR='+  this.props.ID_OGR + '&ModuleName=TinTuc' ,
        {
		// 	headers:new Headers ({
        //     'Access-Control-Allow-Origin' : '*'
        // })
	}
        )
            .then(response => response.json())
            .then(lstContent => this.setState({lstContent}))
            .catch(e => {
                console.log(e);
                return e;
            });
      }
    render(){
		const lstContent =  this.state.lstContent;
		const tdStyle = {
		    paddingLeft: '4px',
			paddingRight: '4px'
		} as const;
		const pStyle={
			color: "#000 !important" 
		}
        if(this.props.STT%2===0)
        {
        return (
            
			<section id="about" className="about">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>{this.props.NAME_OGR}</h2>
                    </div>
                    <div className="row content">
                        <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch order-1 order-lg-1 img" data-aos="fade-right" data-aos-delay={100}>
                            <img src={this.props.ImageUrl} className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 align-items-stretch order-2 order-lg-2 content" data-aos="fade-left" data-aos-delay={100}>
                            <p>
                                <ul>
                                {
                                    lstContent.map((link:any) => 
                                    <li key={ link.NO_OBJ.toString() }><a className="list-group-item list-group-item-action" href={link.LINK_NEW }>{link.NAME_OBJ }</a></li>
                                    )
                                }
                                </ul>
                            </p>
                            <a id="xt_tc" href={ this.props.LINK_GROUP} className="btn-learn-more">Xem thêm</a>
                        </div>
                    </div>
                </div>
        </section>
  
        );
        }
        else
        {
           return (
            <section id="why-us" className="why-us section-bg">
            <div className="container-fluid" data-aos="fade-up">
      
              <div className="row">
      
                <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
      
                  {/* <div className="content">
                    <h3><strong>{this.props.NAME_OGR}</strong></h3>
                  </div> */}
                  <div className="section-title">
                        <h2>{this.props.NAME_OGR}</h2>
                    </div>
                  <div className="accordion-list">
                   <div className="list-group">
                       <ul>
                       {
                                    lstContent.map((link:any) => 
                                    <li key={ link.NO_OBJ.toString() }><a className="list-group-item list-group-item-action" href={link.LINK_NEW }>{link.NAME_OBJ }</a></li>
                                    )
                                }
                      </ul>
                  </div>
                  <a id="xt_nc" href={ this.props.LINK_GROUP} className="btn-learn-more">Xem thêm</a>
                  </div>
      
                </div>
      
                <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage: 'url(' + this.props.ImageUrl +')'}}  data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
              </div>
      
            </div>
          </section>
           );     

        }
    }

}

export default LastNewsFullWidth;
