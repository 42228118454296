import React from 'react';
import { Pagination } from 'react-bootstrap';
import configData from "../../config.json";
import NewsItem from './NewsItem';
import ReactPaginate from 'react-paginate';
import '../TinTuc/paging.css';
import { isBuffer } from 'util';
import equal from 'fast-deep-equal'

class NewsList extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
			RowPerPage:4,
			PageIndex:1,
			pageCount:1
		}
	}
	componentDidMount() {
         
		//lay so dong, phan trang
        this.LoadData();
      }

	  componentDidUpdate(prevProps) {
		if(!equal(this.props.ID_OGR, prevProps.ID_OGR)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
		{
			this.LoadData();
		}
	  } 
	componentWillMount(){
		 
	}
    
	LoadData(){
		var ID_OGR=this.props.ID_OGR;
		if (typeof ID_OGR === "undefined")
		{
			ID_OGR=0;
		}
		fetch(
			configData.serviceUrl+ '/api/News/ContentViewListCount?ID_OTY='+ this.props.ID_OTY + '&ID_OGR='+ ID_OGR ,
			{headers:new Headers ({
				'Access-Control-Allow-Origin' : '*'
			})}
        
        ) 
		    .then(response => response.json())
            .then(dataJs => {
			  if(dataJs.Status===200)
			  {
				let count=dataJs.Data;
				let pageCount=Math.ceil(count/this.state.RowPerPage);
				this.setState({
					pageCount: pageCount
				});
			  }
			})
            .catch(e => {
                console.log(e);
            });

			fetch(
				configData.serviceUrl+ '/api/News/ContentViewList?ID_OTY='+ this.props.ID_OTY + '&ID_OGR='+ ID_OGR + '&PageIndex=' +this.state.PageIndex+ '&RowPerPage=' +this.state.RowPerPage ,
				{headers:new Headers ({
					'Access-Control-Allow-Origin' : '*'
				})}
			
			) 
				.then(response => response.json())
				.then(dataJs => {
					console.log(dataJs);
					this.setState({
						dataJs: dataJs.Data
					});
				})
				.catch(e => {
					console.log(e);
					
				   
				});
	}

	handlePageClick = (data) => {
		let selected = data.selected + 1;
		this.setState({ PageIndex: selected },
		  () => {
		  this.componentDidMount();
		});
	  };

	  showPage = () => {
            
		if (this.state.pageCount > 1 ) {
		
		return (
			<>
				 <ReactPaginate
					previousLabel={'<'}
					nextLabel={'>'}
					breakLabel={'...'}
					breakClassName={'break-me'}
					pageCount={this.state.pageCount}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={this.handlePageClick}
					containerClassName={'page-pagination none-style'}
					activeClassName={'active'}
					/>
			</>
			 );
		} 
		else
		 {
			 return (
					<div>
					</div>
				);
		 }
	};

    render(){
		var dataJs =  this.state.dataJs;
        if (typeof dataJs === "undefined")
		{
			return (
				<div>
					<div id="news-main" className="news-site-main">
					</div>
				</div>
				);
		}
		else
		{
			return (
				<div>
					<div id="news-main" className="news-site-main">
						{
							dataJs.map((item:any) => 
							<NewsItem item={item} ID_OTY={this.props.ID_OTY} Module={this.props.Module}/>
							)
						}
					</div>
					{this.showPage()}
				</div>
			);
		}

        
    }

}

export default NewsList;
