 
import React, { Component } from "react";
import { useLocation, NavLink, BrowserRouter } from "react-router-dom";
import queryString from 'query-string';
import { Nav } from "react-bootstrap";

 


class Sidebar extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
	}
	componentDidMount() {
      }
  
      componentWillMount() {
        // axios commands to get navigation bar setting it newpage state
       
    }
    render(){

   
      const activeRoute = (routeName) => {
        return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
      };

        return (
           <div className="sidebar" data-image={this.props.image} data-color={this.props.color}>
            <div
                className="sidebar-background"
                style={{
                backgroundImage: "url(" + this.props.image + ")",
                }}
            />
            <div className="sidebar-wrapper">
                <div className="logo d-flex align-items-center justify-content-start">
                <a
                    href="."
                    className="simple-text logo-mini mx-1"
                >
                    <div className="logo-img">
                    <img
                        src="./assets/img/logo.png"
                        alt="SaigonIT"
                    />
                    </div>
                </a>
                <br/>
               
                </div> 
                
        <BrowserRouter>
       
        <Nav>
          {this.props.routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li
                  className={
                    prop.upgrade
                      ? "active active-pro"
                      : activeRoute(prop.path)
                  }
                  key={key}
                >
                  <NavLink
                    to={ prop.path}
                    className="nav-link"
                    activeClassName="active"
                    onClick={()=>{window.location.replace(prop.path);}} 
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            return null;
          })}
        </Nav>
        </BrowserRouter>
      </div>
    </div>
        );
    }
}
export default Sidebar;
