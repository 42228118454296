import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../home/stylePage.css'; 
 
import queryString from 'query-string';
 
import {  Container, Row,Col } from 'react-bootstrap';
import NewsList from '../TinTuc/NewsList';
import ContentView from '../Commons/ContentView';
import LastNew from '../Commons/LastNew';
import TopView from '../Commons/TopView';
import RelatedNews from '../Commons/RelatedNews';
import BreadCrumbNews from '../Commons/BreadCrumbNews';
import BreadCrumbs from '../Commons/BreadCrumbs';
import {Helmet} from 'react-helmet'; 
import configData from "../../config.json";

class TinTuc extends React.Component<any, any> {
              
          constructor(props:any) {
              super(props);
              this.state = {
                ID_OTY: 9010,
                ID_OGR: 0,
                NO_OBJ: 0,
                KeySign:'',
                PageIndex:1
              }
          }
          componentDidMount() {
              const values = queryString.parse(this.props.location.search);

              this.setState({
                ID_OGR: values.m,
                NO_OBJ:values.NO_OBJ,
                KeySign: values.KeySign,
                PageIndex:values.PageIndex
              });
            }

          componentWillMount(){
          
          }


          render(){

            const div1 = {
              paddingLeft : '10px'
          
            }  as const;
            
            const containerRow = {
              paddingTop : '15px'
          
            }  as const;
            let rs;
            if(this.state.NO_OBJ ===0 || this.state.NO_OBJ===null || this.state.NO_OBJ===undefined)
            {
              rs= <NewsList ID_OTY={this.state.ID_OTY} ID_OGR={this.state.ID_OGR} Module="GioiThieu" PageIndex={this.state.PageIndex} ></NewsList>;
            }
            else 
            {
              
              rs=<ContentView ID_OTY={this.state.ID_OTY} NO_OBJ={this.state.NO_OBJ} Module="GioiThieu" KeySign={this.state.KeySign} ></ContentView>;
            }
            return (
              <div className="_container"  >
                 <Helmet>
                  <title>{configData.CompanyName+ " - Giới thiệu"} </title>
                  <meta name="description" content={ configData.Description + ", Giới thiệu"} />
            	  </Helmet>

                 <section className="inner-page">
                    <div>
                     <BreadCrumbs ></BreadCrumbs>
                    </div>
                    <Container style={containerRow}>
                        <Row>
                          <Col sm={9}> 
                              {rs}
                          </Col>
                          <Col sm={3} style={div1}>
                              <TopView ID_OTY={this.state.ID_OTY} ID_OGR={this.state.ID_OGR} Top="3"></TopView>
                              <LastNew ID_OTY={this.state.ID_OTY} ID_OGR={this.state.ID_OGR} PageIndex="1" RowPerPage="3" ></LastNew>
                              <RelatedNews ID_OTY={this.state.ID_OTY} ID_OGR={this.state.ID_OGR} PageIndex="1" RowPerPage="3" ></RelatedNews>
                          </Col>
                        </Row>
                    </Container>
                  </section>
              </div>
              
              );
          }

}

export default TinTuc;
