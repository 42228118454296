import React from 'react';
import '../SanPham/product.css';
import configData from '../../config.json';
import AccountService from '../../services/AccountService';
import queryString from 'query-string';

class ProjectFilter extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
        this.state = {
			dataJs: []
		}
	}
	componentDidMount() {
        
        //  if(configData.ProductFilter!== "SaigonIT")
        //  {
        fetch(
			configData.serviceUrl+ '/api/News/GetObjectGroupAll?ID_OTY='+ this.props.ID_OTY,
			{headers:new Headers ({
                'Authorization' : AccountService.Token()
			})}
        ) 
		    .then(response => response.json())
            .then(dataJs => {
				this.setState({
					dataJs: dataJs.Data
				});
			})
            .catch(e => {
                console.log(e);
            });
            
        // }
        
      }
      
	componentWillMount(){
        const query= queryString.parse(window.location.search);
        if(query.m)
        {
            setTimeout(function(){ 
            var li= document.getElementById("li_"+ query.m);
            if(li)
            {
                li.click();
                console.log(li);
            }
        }, 500);
        }
	}
    render(){
        let all;
        const dataJs =  this.state.dataJs;
        let count=0;
        dataJs.map((link:any) =>  link.ID_OGRP? count=count+0 :count++);
        if(count>1)
        {
            all=<li className="nav-item">
                    <a data-filter="*" className="nav-link active" href="#">Tất cả</a>
                </li>;
        }
        return (
            <nav id="filters" className="project_filters filters navbar navbar-expand-sm bg-light justify-content-center">
                <ul className="navbar-nav">
                    {all}
                    {
                    	dataJs.map((link:any) => link.ID_OGRP? "" : <li   className="nav-item" key={ link.ID_OGR.toString() }><a id={ "li_"+ link.ID_OGR.toString() } data-filter={ ".pro"+ link.ID_OGR.toString()} className="nav-link" href="#">{link.NAME_OGR}</a></li> )
				    } 
                </ul>
            </nav>
        //     <div id="filters" className="filters">
        //     <a href="#" data-filter="*" className="active">All</a>
        //     <a href="#" data-filter=".web">Web</a>
        //     <a href="#" data-filter=".design">Design</a>
        //     <a href="#" data-filter=".branding">Branding</a>
        //     <a href="#" data-filter=".photography">Photography</a>
        //     <a href="#" data-filter=".sgit">sgit</a>
        //   </div>
            
        );
    }
}
export default ProjectFilter;

