 
import AdminTinTuc from './TinTuc/TinTuc';
import AdminNhomTinTuc from './TinTuc/NhomTinTuc';
 
import AdminGioiThieu from './GioiThieu/GioiThieu';
import AdminThuVien from './ThuVien/ThuVien';

import AdminSanPham from './SanPham/SanPham';
import AdminGioHang from './GioHang/GioHang';

import AdminConfig from './Config/GlobalConfig';

const dashboardRoutes = [
  {
    path: "/AdminTinTuc",
    name: "Tin tức",
    icon: "nc-icon nc-alien-33",
    component: AdminTinTuc,
    layout: "/Admin",
  },
  {
    path: "/AdminNhomTinTuc",
    name: "Nhóm tin tức",
    icon: "nc-icon nc-alien-33",
    component: AdminNhomTinTuc,
    layout: "/Admin",
  } ,
  {
    path: "/AdminGioiThieu",
    name: "Giới thiệu",
    icon: "nc-icon nc-alien-33",
    component: AdminGioiThieu,
    layout: "/Admin",
  },
  {
    path: "/AdminThuVien",
    name: "Thư viện",
    icon: "nc-icon nc-alien-33",
    component: AdminThuVien,
    layout: "/Admin",
  },
  {
    path: "/AdminSanPham",
    name: "Sản phẩm",
    icon: "nc-icon nc-alien-33",
    component: AdminSanPham,
    layout: "/Admin",
  },
  {
    path: "/AdminGioHang",
    name: "Đơn hàng",
    icon: "nc-icon nc-alien-33",
    component: AdminGioHang,
    layout: "/Admin",
  },
  {
    path: "/AdminConfig",
    name: "Thiết lập chung",
    icon: "nc-icon nc-alien-33",
    component: AdminConfig,
    layout: "/Admin",
  }
];

export default dashboardRoutes;