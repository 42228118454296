import React from 'react';
import configData from "../../config.json";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'; 
import '../Commons/ContentView.css';
import ImageView from '../Commons/ImageView';
import ShareSocial from '../Commons/ShareSocial';

class ContentView extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: {},
			ID_OTY: 0,
			ID_OGR: 0  
			 
		}
	}
	componentDidMount() {

	 
      
        fetch(
			configData.serviceUrl+ '/api/News/ContentView?NO_OBJ='+this.props.NO_OBJ+'&KeySign='+this.props.KeySign,
			{headers:new Headers ({
				'Access-Control-Allow-Origin' : '*'
				 
			})}
        
        ) 
		    .then(response => response.json())
            .then(dataJs => {
				//console.log(dataJs);
				this.setState({
					dataJs: dataJs.Data
				});
			})
            .catch(e => {
                console.log(e);
            });
      }

	componentWillMount(){
		//let num : any
		//num  = 1
		//if( sessionStorage.getItem('Token') ===  null){
			
		//}else{
			
		//}
		//if(sessionStorage.getItem('ID_OTY') === num ){
		//	this.setState({ID_OTY: true})
		//}
	}


	
	renderTuKhoa = () => {
            
		if (this.state.dataJs.TEXT2 === undefined || this.state.dataJs.TEXT2 === null ) {
		
		return (
			<>
				  <a href="" rel="tag">#Mặc định</a> 
			</>
			 );
		} 
		else
		 {
			 return (
			     	<>

					 </>
				);
		 }
	};
    render(){
		 	 
		const img1 = {
			width : '100%',
			
			
			dataLlStatus : 'loaded'
		  }  as const;

        return (
			<div className="blog-post" >
				<div className="post-inner">
						
					<div className="entry-media">
						<div className="post-cat">
							<span className="posted-in">
								<a href={"/"+this.props.Module + "/?m=" + this.state.dataJs.ID_OGR} rel="category tag">{this.state.dataJs.NAME_OGR}</a>
							</span>
						</div>
						 
						<img src={this.state.dataJs.ImageUrl} className="attachment-post-thumbnail size-post-thumbnail wp-post-image lazyloaded" style={img1} />
						 
					</div>


					<div className="inner-post">
						<div className="entry-header">

								<div className="entry-meta">
								<span className="posted-on">{this.state.dataJs.NgayDang}</span>
								</div> 
							
							<h3 className="entry-title">{this.state.dataJs.NAME_OBJ}</h3>
						</div> 

						<div className="entry-summary the-excerpt">

						{ ReactHtmlParser(this.state.dataJs.HTML) }

						</div> 

						<div className="entry-footer clearfix">
                           <div className="tagcloud">
							   {this.renderTuKhoa()}
							{/*<a href="" rel="tag">#tag1</a>*/}
							 
							 </div>
						</div>
						<div className="share-post">
                            <ShareSocial ImageUrl={this.state.dataJs.ImageUrl} MailSubject={ this.state.dataJs.NAME_OBJ } MailBody={ this.state.dataJs.NOTE } />
                        </div>
						
					</div>
				</div>
			</div>
           
        );
    }
}

export default ContentView;
