import axios from 'axios';
import React  from 'react';
import configData from "../../../config.json";
import CookieService from '../../../services/CookieService';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ImageView from '../../Commons/ImageView';

import { Editor, OriginalTools } from 'react-bootstrap-editor';
import {  Container, Row,Col, Form, Button } from 'react-bootstrap';


class ChiTietGioHang extends React.Component<any, any> {
              
          constructor(props:any) {
              super(props);
              this.state = {
                dataJs: {}
              }
          }
          componentDidMount() {

            if(this.props.NO_VOU===0)
            {
                this.setState({
                    dataJs: this.props.row
                });
            }
            else
            {
            fetch(
                configData.serviceUrl+ '/api/Admin/VoucherDetail?NO_VOU='+this.props.NO_VOU,
                {headers:new Headers ({
                    'Access-Control-Allow-Origin' : '*',
                    'Authorization' : CookieService.get("access_token") 
                })}
            
            ) 
                .then(response => response.json())
                .then(dataJs => {
                    //console.log(dataJs);
                    this.setState({
                        dataJs: dataJs.Data
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            
            }
        }

          componentWillMount(){
          
          }

          changeHandler=(e: any)=>{
            let dataJs = this.state.dataJs;
            dataJs[e.target.name]= e.target.value;
            this.setState({dataJs: dataJs});
          }
          changeCheck=(e: any)=>{
            let dataJs = this.state.dataJs;
            dataJs[e.target.name]= e.target.checked;
            this.setState({dataJs: dataJs});
          }
          changeHTML=(e: any)=>{
            let dataJs = this.state.dataJs;
            dataJs["HTML"]= e;
            this.setState({dataJs: dataJs});
          }

          submitHandler = (e: any) =>{
            e.preventDefault();
            
            fetch(
                configData.serviceUrl+ '/api/Admin/CancleVoucher',
                {
                    method: 'POST' ,
                    body: JSON.stringify(this.state.dataJs),
                    headers: new Headers ({
                    'Access-Control-Allow-Origin' : '*',
                    'Authorization' :  CookieService.get("access_token") ,
                    'Content-Type' : 'application/json'
                    })
                    
                }
            ) 
                .then(response => response.json())
                .then(dataJs => {
                     
                   // if(this.state.dataJs.NO_OBJ===0)
                    {
                        window.location.reload();
                    }
                })
                .catch(e => {
                    console.log(e);
                });
          }

        
        
          
        // file upload is complete
        fileData = () => {
            
            if (this.state.selectedFile) {
            return (
                <div>
                <h2>Chi tiết file:</h2>
                        
                <p>File Name: {this.state.selectedFile.name}</p>
                <p>File Type: {this.state.selectedFile.type}</p>
                </div>
                 );
            } 
            else
             {
                 return (
                        <div>
                        <br />
                        <h4>Chọn file để upload</h4>
                        </div>
                    );
             }
        };

        optionGroupData = () => {
            
            if (this.props.groupJs.length > 0 ) {
            
            return (
                <>
                    {
                    this.props.groupJs.map((item: any, index: number) => (
                        <option value={item.ID_OGR}>{item.NAME_OGR}</option>
                    ))
                    }
                </>
                 );
            } 
            else
             {
                 return (
                        <div>
                        </div>
                    );
             }
        };

          render(){

            const img1 = {
                width : '130px',
                height : '105px',
                resize : 'both'
              }  as const;

            return (
              <div   >
                 
                <Form>
                   <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Mã đơn hàng</Form.Label>
                        <Col sm="10"> <Form.Control  name="ID_VOU" type="text" size="lg" defaultValue={this.props.row.ID_VOU} readOnly   />   </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Ngày đặt mua</Form.Label>
                        <Col sm="10"> <Form.Control  name="DATE_VOU" type="text" size="lg" defaultValue={this.props.row.DATE_VOU} readOnly   />   </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Mã khách hàng</Form.Label>
                        <Col sm="10"> <Form.Control  name="ID_OBJ" type="text" size="lg" defaultValue={this.props.row.ID_OBJ} readOnly   />   </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Tên khách hàng</Form.Label>
                        <Col sm="10"> <Form.Control  name="NAME_OBJ" type="text" size="lg" defaultValue={this.props.row.NAME_OBJ} readOnly   />   </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Người nhận</Form.Label>
                        <Col sm="10"> <Form.Control  name="REPRESENT" type="text" size="lg" defaultValue={this.props.row.REPRESENT} readOnly   />   </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Địa chỉ</Form.Label>
                        <Col sm="10"> <Form.Control  name="ADDRESS" type="text" size="lg" defaultValue={this.props.row.ADDRESS} readOnly   />   </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Tổng tiền</Form.Label>
                        <Col sm="10"> <Form.Control name="TOTAL_SUM" type="number" size="lg" defaultValue={this.props.row.TOTAL_SUM} readOnly/>  </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Tình trạng</Form.Label>
                        <Col sm="10"> <Form.Control  name="NAME_STA" type="text" size="lg" defaultValue={this.props.row.NAME_STA} readOnly   />   </Col>
                    </Form.Group>
                     
                  
                    <Form.Group as={Row} className="mb-3" >
                    <Col sm="12">  
                        <div>
                        Danh sách sản phẩm    
                         </div>
                        </Col>
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={this.submitHandler}  >
                        Hủy đơn hàng
                    </Button>
                    </Form>
              </div>
              
              );
          }

}

export default ChiTietGioHang;
