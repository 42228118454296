import React from 'react';
import '../SanPham/product.css';
import UrlService from '../../services/UrlService';
import ImageView from '../Commons/ImageView';

class ShareSocial extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
	}
	componentDidMount() {
        
      }
	componentWillMount(){
	}
    render(){
        return (
            <div className="otf-social-share clearfix shape-circle">
                                <a className="share-facebook" href={ "https://www.facebook.com/sharer/sharer.php?u=" + UrlService.UrlCurent() } target="_blank">
                                    <i className="bi bi-facebook" />
                                </a>
                                <a className="share-twitter" href= {"https://twitter.com/intent/tweet?text=SaigonIT&url=" + UrlService.UrlCurent()} target="_blank">
                                    <i className="bi bi-twitter" />
                                </a>
                                <a className="share-pinterest" href={"https://pinterest.com/pin/create/button/?url="+ UrlService.UrlCurent()+ "&media=" + this.props.ImageUrl} data-pin-custom="true" target="_blank">
                                    <i className="fa fa-pinterest" />
                                </a>
                                <a className="share-email" href={"mailto:" + (this.props.MailSubject?  "?Subject="+ this.props.MailSubject : "") + (this.props.MailBody? "&Body=" + this.props.MailBody: "")} >
                                    <i className="fa fa-envelope" />
                                </a>
            </div>
        );
    }
}
export default ShareSocial;

