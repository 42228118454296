import React from 'react';
import UrlService from '../../services/UrlService';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CookieService from '../../services/CookieService';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import {  Container, Row,Col } from 'react-bootstrap';
import { TiDeleteOutline } from 'react-icons/ti';
import BreadCrumbNews from '../Commons/BreadCrumbNews';
import '../GioHang/GioHang.css'; 
import axios from 'axios';
import configData from '../../config.json';
import queryString from 'query-string';
import AccountService from '../../services/AccountService';

class DonHang extends React.Component<any, any> {
	node: any;
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
		}
		// this.deleteProduct = this.deleteProduct.bind(this);
	}
	componentDidMount() {
        const Token= AccountService.Token();
		const Roles= AccountService.Roles();
		if(Token)
		{
			fetch(
				configData.serviceUrl+ '/api/Admin/GetShopCart?PageIndex=0&RowPerPage=0',
				{headers:new Headers ({
					'Access-Control-Allow-Origin' : '*',
					'Authorization' :  CookieService.get("access_token") ,
				})}
			) 
				.then(response => response.json())
				.then(dataJs => {
					if(dataJs.Status==200)
					{
					this.setState({
						dataJs: dataJs.Data
					});
					}
				})
				.catch(e => {
					console.log(e);
				});
		}
		else	
		{
			window.location.href= UrlService.UrlRoot()+"/DangNhap";
		}
        
      }

	componentWillMount(){
		
	}

	
	
	actionsFormatter (cell, row ,rowIndex, {parents})
	{
		return <ul className="list-inline m-0">
	
		<li className="list-inline-item">
			<button className="btn btn-danger btn-sm rounded-0" onClick={ () => 
			{
				console.log(row.NO_VOU);
				console.log(parents[rowIndex].NO_VOU);
				window.location.href = UrlService.UrlRoot()+"/ChiTietDonHang?id=" + row.NO_VOU;
			}
		}
			type="button" data-toggle="tooltip" data-placement="top" title="Chi tiết đơn hàng">
			<i className="fa fa-external-link"></i>
			</button>
		</li>
	</ul>
	}
    render(){
		// let dataCookie= CookieService.get("orderData");
		// this.setState({ dataJs : dataCookie});
		const dataJs= this.state.dataJs;
      
		function priceFormatter(cell, row) {
			return (
			  <span>{parseInt(cell).toLocaleString() }</span>
			);
		}
		 
        const columns = [
			{
                dataField: 'ID_VOU',
                text: 'Mã đơn hàng',
                sort: true,
				editable: false
            },
            {
			dataField: 'DATE_VOU',
			text: 'Ngày đặt',
			editable: false,
			formatter: (cell) => {
				let dateObj = cell;
				if (typeof cell !== 'object') {
				  dateObj = new Date(cell);
				}
				return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
			}
			}
			
			,{
				dataField: 'NAME_STA',
				text: 'Tình trạng',
				editable: false
			}
			,{
				dataField: 'actions',
				text: 'Thao tác',
				isDummyField: true,
				csvExport: false,
				formatter: this.actionsFormatter,
				formatExtraData: { parents: this.state.dataJs },
				editable: false
			  }
            
		];

	 
		  const rowStyle={ height: "50px"  };
		  

		  function afterSaveCell(oldValue, newValue, row, column) {
			console.log(row);
			console.log(column);
			try{
				 
				
			}
			catch(err) {
				console.log("Lỗi lưu: "+err);
			}
		}

        return (
			<div className="_container"  >
			<section className="inner-page">
			   <div>
				{/* <BreadCrumbNews backgroundImage="../assets/img/bg-pheader.jpg"></BreadCrumbNews> */}
			   </div>
			   <div style={{paddingTop: '90px', paddingBottom:'110px'}}>
	  
			   <Container >
				   <Row  >
					 <Col sm={12}> 
						<BootstrapTable
							ref={ n => this.node = n }
							keyField='ID_VOU' 
							data={dataJs} 
							columns={ columns } 
							hover
							condensed
							noDataIndication="Chưa có dữ liệu"
							cellEdit={ cellEditFactory({
								mode: 'click',
								autoSelectText: true,
								blurToSave: true
								,afterSaveCell
							}) }
						
							filter={ filterFactory() }
					
							wrapperClasses="table-responsive"
							rowStyle={ rowStyle }
							//rowEvents={ rowEvents }
							// expandRow={ expandRow }
						/>
					 </Col>
				 
				   </Row>
				   
			   </Container>
			   </div>
			 </section>
		 </div>
			 
        );
    }

}

export default DonHang;
