import React from 'react';
import UrlService from '../../services/UrlService';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CookieService from '../../services/CookieService';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import {  Container, Row,Col } from 'react-bootstrap';
import { TiDeleteOutline } from 'react-icons/ti';
import BreadCrumbNews from '../Commons/BreadCrumbNews';
import '../GioHang/GioHang.css'; 
import axios from 'axios';
import configData from '../../config.json';
import queryString from 'query-string';
import AccountService from '../../services/AccountService';
import { data } from 'jquery';
import moment from 'moment';

class ChiTietDonHang extends React.Component<any, any> {
	node: any;
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
			orderDetail:[]
		}
		// this.deleteProduct = this.deleteProduct.bind(this);
	}
	componentDidMount() {
		const query= queryString.parse(window.location.search);
        const Token= AccountService.Token();
		const Roles= AccountService.Roles();
		if(Token)
		{
			fetch(
				configData.serviceUrl+ '/api/Admin/GetOrderMaster?NO_VOU='+ query.id,
				{headers:new Headers ({
					'Access-Control-Allow-Origin' : '*',
					'Authorization' :  CookieService.get("access_token") ,
				})}
			) 
				.then(response => response.json())
				.then(dataJs => {
					if(dataJs.Status==200)
					{
						this.setState({
						dataJs: dataJs.Data
					});
					}
				})
				.catch(e => {
					console.log(e);
				});

				fetch(
					configData.serviceUrl+ '/api/Admin/GetOrderDetail?NO_VOU='+ query.id,
					{headers:new Headers ({
						'Access-Control-Allow-Origin' : '*',
						'Authorization' :  CookieService.get("access_token") ,
					})}
				) 
					.then(response => response.json())
					.then(orderDetail => {
						if(orderDetail.Status==200)
						{
							this.setState({
							orderDetail: orderDetail.Data
						});
						}
					})
					.catch(e => {
						console.log(e);
					});
		}
		else	
		{
			window.location.href= UrlService.UrlRoot()+"/DangNhap";
		}
        
      }

	componentWillMount(){
		
	}

	
	
	actionsFormatter (cell, row ,rowIndex, {parents})
	{
		return <ul className="list-inline m-0">
	
		<li className="list-inline-item">
			<button className="btn btn-danger btn-sm rounded-0" onClick={ () => 
			{
				console.log(row.NO_VOU);
				console.log(parents[rowIndex].NO_VOU);
				window.location.href = UrlService.UrlRoot()+"/DonHang?id=" + row.NO_VOU;
			}
		}
			type="button" data-toggle="tooltip" data-placement="top" title="Chi tiết đơn hàng">
			<i className="fa fa-external-link"></i>
			</button>
		</li>
	</ul>
	}
    render(){
		// let dataCookie= CookieService.get("orderData");
		// this.setState({ dataJs : dataCookie});
		const dataJs= this.state.dataJs;
		const orderDetail=this.state.orderDetail;
		console.log(dataJs);
		function priceFormatter(cell, row) {
			return (
			  <span>{parseInt(cell).toLocaleString() }</span>
			);
		}
		 
        const columns = [
			{
                dataField: 'code',
                text: 'Mã sản phẩm',
                sort: true,
				editable: false
            },
            {
			dataField: 'name',
			text: 'Tên sản phẩm',
			editable: false
			}
			,{
				dataField: 'quantity',
				text: 'Số lượng',
				type: 'number',
				editorStyle: {
					backgroundColor: '#007bff',
					color:'#f6f6f6'
				  },
				validator: (newValue, row, column) => {
					if (isNaN(newValue)) {
					  return {
						valid: false,
						message: 'Chưa nhập số lượng'
					  };
					}
					if (newValue <= 0) {
					  return {
						valid: false,
						message: 'Chưa nhập số lượng'
					  };
					}
					return true;
				  }
			} 
			,{
				dataField: 'price',
				text: 'Đơn giá',
				editable: false,
				formatter: priceFormatter
			}
			,{
				dataField: 'total',
				text: 'Thành tiền',
				editable: false,
				formatter: priceFormatter
			}
			// ,{
			// 	dataField: 'actions',
			// 	text: 'Thao tác',
			// 	isDummyField: true,
			// 	csvExport: false,
			// 	formatter: this.actionsFormatter,
			// 	formatExtraData: { parents: this.state.dataJs },
			// 	editable: false
			//   }
            
		];

	 
		  const rowStyle={ height: "20px"  };
		  

		  function afterSaveCell(oldValue, newValue, row, column) {
			console.log(row);
			console.log(column);
			try{
				 
				
			}
			catch(err) {
				console.log("Lỗi lưu: "+err);
			}
		}

        return (
			<div className="_container"  >
			<section className="inner-page">
			   <div style={{paddingTop: '90px', paddingBottom:'110px'}}>
			   
			   <Container >
				   <Row>
				   <div className="card" style={{width: '100%'}}>
						<div className="card-header">
						Thông tin đơn hàng
						</div>
						<div>
							<Container>
								<Row style={{ height: "40px" }}>
									<Col lg={6} sm={12}>
										Người đặt hàng: <b> { dataJs.Name }</b>
									</Col>
									<Col lg={6} sm={12}>
										Mã đơn hàng: <b>{  dataJs.ID_VOU }</b>
									</Col>
								</Row>
								<Row style={{ height: "40px" }}>
									<Col lg={6} sm={12}>
									Điện thoại: <b>{ dataJs.Phone}</b>
									</Col>
									<Col lg={6} sm={12}>
									Email: <b>{dataJs.Email}</b>
									</Col>
								</Row>
								<Row style={{ height: "40px" }}>
									<Col lg={6} sm={12}>
									Ngày đặt hàng: <b>{ moment(dataJs.DATE_SYS).format("DD/MM/YYYY HH:mm")  }</b>
									</Col>
									<Col lg={6} sm={12}>
									Địa chỉ: <b>{dataJs.Address}</b>
									</Col>
								</Row>
								<Row style={{ height: "40px" }}>
									<Col lg={6} sm={12}>
									Người nhận hàng: <b>{ dataJs.Receiver}</b>
									</Col>
									<Col lg={6} sm={12}>
									Địa chỉ giao hàng: <b>{dataJs.DeliveryAddress}</b>
									</Col>
								</Row>
								<Row style={{ height: "40px" }}>
									<Col lg={6} sm={12}>
									Ghi chú: <b>{ dataJs.NOTE}</b>
									</Col>
									<Col lg={6} sm={12}>
									Tình trạng: <b>{dataJs.StatusText}</b>
									</Col>
								</Row>
							</Container>
						</div>
						{/* <ul className="list-group list-group-flush">
							<li className="list-group-item">
								Mã đơn hàng: {  dataJs.ID_VOU }
							</li>
							<li className="list-group-item">Ngày đặt hàng: { moment(dataJs.DATE_SYS).format("DD/MM/YYYY HH:mm")  }</li>
							<li className="list-group-item">Tình trạng: {dataJs.NAME_STA}</li>
						</ul> */}
					</div>
				   </Row>
				   <Row>
					 <Col sm={12}> 
						<BootstrapTable
							ref={ n => this.node = n }
							keyField='code' 
							data={orderDetail} 
							columns={ columns } 
							hover
							condensed
							noDataIndication="Chưa có dữ liệu"
							cellEdit={ cellEditFactory({
								mode: 'click',
								autoSelectText: true,
								blurToSave: true
								,afterSaveCell
							}) }
						
							filter={ filterFactory() }
					
							wrapperClasses="table-responsive"
							rowStyle={ rowStyle }
							//rowEvents={ rowEvents }
							// expandRow={ expandRow }
						/>
					 </Col>
				 
				   </Row>
				   <Row>
				   <a className="btn btn-primary" href={ UrlService.UrlRoot()+"/DonHang" } role="button">Quay lại</a>
				   </Row>
			   </Container>
			   
			   </div>
			 </section>
		 </div>
			 
        );
    }

}

export default ChiTietDonHang;
