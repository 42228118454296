import React from 'react';
import '../SanPham/product.css';
import UrlService from '../../services/UrlService';
import ImageView from '../Commons/ImageView';

class ProductsItem extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
	}
	componentDidMount() {
        
      }
	componentWillMount(){
	}
    render(){
        return (
            // <div className= "item web col-sm-6 col-md-3 col-lg-3 mb-3">
			<div className= { "item pro"+ this.props.ID_OGRP +  " col-sm-6 col-md-4 col-lg-3 mb-3" } >
			       <a className="item-wrap fancybox">
					{/* <a href={ UrlService.UrlRoot() +"/" + this.props.Url} className="item-wrap fancybox">*/}
						<div className="work-info">
							 
						</div>
						<ImageView className="img-fluid" src={ UrlService.UrlRoot() + this.props.ImageUrl } alt={ this.props.Name } />
						{/* <img className="img-fluid" src={ UrlService.UrlRoot() + this.props.ImageUrl } /> */}
						<div className="work-info2">
							{/*<h3>{this.props.Description}</h3>*/}
							<span>{this.props.Name}</span>
						</div>
						
					</a>
		    </div>
        );
    }
}
export default ProductsItem;

