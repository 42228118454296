import React from 'react';
import UrlService from '../../services/UrlService';
import CookieService from '../../services/CookieService';
import queryString from 'query-string';
import axios from 'axios';
import configData from '../../config.json';

class ProductOrder extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
            register : {
                Email: 'abc@gmail.com',
                Password: '1',
                Name : 'a',
                Username: 'd',
                Token:'re'
              },
		}
	}
	componentDidMount() {
      }

	componentWillMount(){
		 
	}
    existProduct(currentOrder, listOrder)
    {
        var listOrderJson = JSON.stringify(listOrder);
        $.each(JSON.parse(listOrderJson) , function (key, value) {
            if(currentOrder.id ===value.id){
             return true;
           }
           return false;
          });
          return false;
    }
    setOrderProduct=()=>{
        const query= queryString.parse(window.location.search);
        if(query && query.m && query.id)
        {
            // let dataCookie= CookieService.get("orderData");
            let curentOrder= { m : "1", id : query.id, code: this.props.ProductCode, name: this.props.ProductName, price: this.props.ProductPrice, quantity: 1, total: this.props.ProductPrice };
            let orderData=localStorage.getItem("orderData")? localStorage.getItem("orderData") : "";
            if(orderData)
            {
                var dataCookie= JSON.parse(orderData);
            
            
                let existProduct=false;
                dataCookie.forEach((item, index) => { 
                    if(item.id===curentOrder.id)
                    {
                        existProduct=true;
                        item.code= curentOrder.code;
                        item.name=curentOrder.name;
                        item.price=curentOrder.price;
                        item.quantity=item.quantity+1;
                        item.total=item.price * item.quantity;
                        // item.remove=false;
                    }
                 } );
                
                if(!existProduct)
                {
                    dataCookie.push(curentOrder);
                }
            
            //CookieService.setForever("orderData", dataCookie);
            //CookieService.remove("orderData");
            //console.log(dataCookie);
        }
        else    
        {

            dataCookie = [ curentOrder ];
        }
        localStorage.setItem("orderData", JSON.stringify(dataCookie));
        window.location.href= UrlService.UrlRoot() + "/GioHang";
        }
    }
    testDataSet=()=>{
        axios
        .post(configData.serviceUrl + '/api/Products/TestDataSet', this.state.register)
        .then(response =>{
          if(response && response.status===200)
          {
            if(response.data.Status===201)
            {
                
                alert("Chúc mừng đăng ký thành công. Tiến hành đăng nhập");
                //window.location.href= window.location.origin + '/login';
            }
            else  
            {
              
              alert(response.data.Message);
            }
        }}
        ).catch(error =>{
          alert(error);
        })
    }
    render(){
        return (
			<section className="container-flud">
                <div className="container">
                    <div className="elementor-widget-container elementor-widget-wrap text-left">
                        <button type="button" className="btn btn-primary btn-lg" onClick ={()=>this.setOrderProduct()}>
                            Đặt hàng
                        </button>
                    </div>
                    {/* <div className="elementor-widget-container elementor-widget-wrap text-left">
                        <button type="button" className="btn btn-primary btn-lg" onClick ={()=>this.testDataSet()}>
                            Test DataSet
                        </button>
                    </div> */}
                </div>
            </section>
        );
    }

}

export default ProductOrder;
