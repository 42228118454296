import React from 'react';
import configData from "../../config.json";
import ProjectFilter from "../SanPham/ProjectFilter";
import ProductsList from './ProductsList';
import ProductsHeader from './ProductsHeader';
import { Col, Row } from 'react-bootstrap';
import UrlService from '../../services/UrlService';
import ImageView from '../Commons/ImageView';

 

class ProductBodyImg extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
		}
	}
	componentDidMount() {
      }

	componentWillMount(){
		 
	}

    render(){
        return (
			<section className="container-flud">
                <div className="container">
                    <ImageView className="elementer-image" src={this.props.src} />
                    {/* <img className="elementor-image" src={ UrlService.UrlRoot() +"/assets/img/product_detail.jpg" } ></img> */}
                </div>
            </section>
        );
    }

}

export default ProductBodyImg;
