import React from 'react';
import configData from "../../config.json";
import '../Commons/wiget.css';
import ImageView from '../Commons/ImageView';

class RelatedNews extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
			ID_OTY: 0,
			ID_OGR: 0,
		}
	}
	componentDidMount() {
        
        fetch(
			configData.serviceUrl+ '/api/News/RelatedNews?ID_OTY='+ this.props.ID_OTY + '&ID_OGR='+ this.props.ID_OGR + '&PageIndex=' +this.props.PageIndex+ '&RowPerPage=' +this.props.RowPerPage+ '&AbsoluteUri=' +window.location.href,
			{headers:new Headers ({
				'Access-Control-Allow-Origin' : '*'
			})}
        
        ) 
		    .then(response => response.json())
            .then(dataJs => {
				console.log(dataJs);
				this.setState({
					dataJs: dataJs.Data
				});
			})
            .catch(e => {
                console.log(e);
				
               
            });
      }

	componentWillMount(){
		//let num : any
		//num  = 1
		//if( sessionStorage.getItem('Token') ===  null){
			
		//}else{
			
		//}
		//if(sessionStorage.getItem('ID_OTY') === num ){
		//	this.setState({ID_OTY: true})
		//}
	}

    render(){

		 
		const img1 = {
			width : '100%',
			height: 'auto',
			maxWidth: '100%' 
		} as const;
		 
        return (
			<div className="widget-area">

			<section   className="widget widget_recent_news">
				<h5 className="widget-title">Tin liên quan</h5>         
				   <ul className="recent-news clearfix">
							{
							this.state.dataJs.map((item: any, index: number) => (
								<li className="clearfix"> 
									<div className="thumb">
									<a href={item.Url}>
									<ImageView src={item.ImageThumbUrl} className="attachment-thumbnail size-thumbnail wp-post-image lazyloaded" style={img1} />                    
									</a>
									</div>
									<div className="entry-header">
										<h6>
											<a href={item.Url}>{item.NAME_OBJ}</a>
										</h6>
										<span className="post-on">
											<span className="entry-date">{item.NgayDang}</span>
										</span>
									</div>
								</li>
							))}							 
					</ul>

				</section>
            </div>
        );
    }

}

export default RelatedNews;
