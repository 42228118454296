import axios from 'axios';
import React  from 'react';
import configData from "../../../config.json";
import CookieService from '../../../services/CookieService';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ImageView from '../../Commons/ImageView';

import { Editor, OriginalTools } from 'react-bootstrap-editor';
import {  Container, Row,Col, Form, Button } from 'react-bootstrap';


class NhomTinTucDetail extends React.Component<any, any> {
              
          constructor(props:any) {
              super(props);
              this.state = {
                dataJs: {},
                imageUrl:'',
                selectedFile: null
              }
          }
          componentDidMount() {
            
            this.setState({
                dataJs: this.props.row,
                imageUrl: this.props.row['ID_OGR']+"_bgr.png"
            });
          
        }

          componentWillMount(){
          
          }
        
          // On file select (from the pop up)
        onFileChange = event => {
    
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
      
      };
          // On file upload (click the upload button)
         onFileUpload = () => {
    
                
            // Create an object of formData
            const formData = new FormData();
                        
            // Update the formData object
            formData.append(
            "files",
            this.state.selectedFile
            );

            // Details of the uploaded file
            console.log(this.state.selectedFile);

            // Request made to the backend api
            // Send formData object

            fetch(
                configData.serviceUrl+ '/api/Admin/UploadEx?ModunName='+ this.props.ModunName+'&DestFileName='+this.props.row['ID_OGR']+'_bgr.png',
                {
                    method: 'POST' ,
                    body: formData,
                    headers:new Headers ({
                    'Access-Control-Allow-Origin' : '*',
                    'Authorization' : CookieService.get("access_token") 
                   
                    })
                }
            
            )
            .then(response => response.json())
            .then(rss => {
                if(rss.Status===200)
                {
                    this.setState({imageUrl: this.props.row['ID_OGR']+"_bgr.png"});
                    alert("Upload xong!");
                }
                else
                {
                    alert("Upload lỗi: " + rss.Message);
                }
            })
            .catch(e => {
                console.log(e);
            });
                
               
        };
        // file upload is complete
        fileData = () => {
            
            if (this.state.selectedFile) {
            
            return (
                <div>
                <h2>Chi tiết file:</h2>
                        
                <p>File Name: {this.state.selectedFile.name}</p>
                <p>File Type: {this.state.selectedFile.type}</p>
                </div>
                 );
            } 
            else
             {
                 return (
                        <div>
                        <br />
                        <h4>Chọn file để upload</h4>
                        </div>
                    );
             }
        };

        

          render(){

            const img1 = {
                width : '130px',
                height : '105px',
                resize : 'both'
              }  as const;

            return (
              <div>
                 
                <Form>
                    
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2" >Ảnh đại diện</Form.Label>
                        <Col sm="10"> 
                          
                            <img src={"/Upload/TinTuc/"+this.state.imageUrl} style={img1}></img> 
                            <br/>
                            <br/>
                            <div>
                                    <input type="file" onChange={this.onFileChange} />
                                    <Button   onClick={this.onFileUpload}>
                                    Upload...
                                    </Button >
                            </div>
                            {this.fileData()}
                         </Col>
                    </Form.Group>
                    </Form>
              </div>
              
              );
          }

}

export default NhomTinTucDetail;
